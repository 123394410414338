import { projectAlias } from 'data';
import { projectStatuses, saleStatuses } from 'data/project-statuses';
import { BASE_API_URL } from 'env';

export const isTest = false;

export const hardCodeTime = isTest
  ? {
      open_time: '2022-04-16T13:00:00Z',
      fcfs_round_time: '2022-04-17T13:00:00Z',
      close_time: '2022-04-18T13:00:00Z',
    }
  : null;

export const projectData = {
  name: 'Kaizen Finance',
  alias: projectAlias.kaizen,
  whiteListUrl: 'https://docs.google.com/forms/d/e/1FAIpQLSf2hGwFbMnLjxZjLgWsHjx8edImp2mLDDkCelgsFBd1FAnOHA/viewform',
  apiBaseUrl: `${BASE_API_URL}/${projectAlias.kaizen}`,
  addressReceiver: process.env.REACT_APP_ADDRESS_RECEIVER,

  banner: `${process.env.PUBLIC_URL}/assets/imgs/projects/${projectAlias.kaizen}/banner.png`,
  bigBanner: `${process.env.PUBLIC_URL}/assets/imgs/projects/${projectAlias.kaizen}/big-banner.png`,
  inventory: `${process.env.PUBLIC_URL}/assets/imgs/projects/${projectAlias.kaizen}/inventory.png`,
  logo: `${process.env.PUBLIC_URL}/assets/imgs/projects/${projectAlias.kaizen}/logo.png`,
  textLogo: `${process.env.PUBLIC_URL}/assets/imgs/projects/${projectAlias.kaizen}/text-logo.png`,
  url: `/ido/${projectAlias.kaizen}`,
  saleType: 'IDO',
  acceptedTokens: ['BUSD', 'USDT'],
  totalRaise: 20000,
  meta: [
    {
      label: 'Price',
      value: 0.04,
      prefix: '$',
    },
  ],
  starts: 'April 16, 13:00 UTC',
  enable: true,
  status: projectStatuses.completed,
  saleStatus: saleStatuses.soldOut,
  tokenName: 'KZEN',
  tokenSymbol: 'KZEN',
  totalSupply: '1000000000',
  initialSupply: '3000000',
  initialMarketCap: '120000',
  tokenListing: 'April 21',
  registration_open_time: 'TBA',
  registration_close_time: '2022-04-15T13:00:00Z',
  allocation_round_time: '2022-04-16T13:00:00Z',
};
