import React, { useMemo } from 'react';
import clsx from 'clsx';
import { createUseStyles } from 'react-jss';
import { formatNumber, isNumeric } from 'utils/common';
import { Button, LinearProgress, linearProgressClasses } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { LazyLoadImage, trackWindowScroll } from 'react-lazy-load-image-component';
import { projectStatuses } from 'data/project-statuses';
import { propOr } from 'ramda';

export const TotalRaiseProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 18,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    background: '#222325',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 18,
    background: 'linear-gradient(270deg, rgba(255, 239, 94, 1) 0%, rgba(247, 147, 111, 1) 100%)',
  },
}));

const useStyles = createUseStyles(
  {
    projectCard: {
      minHeight: '400px',
      border: '1px solid transparent',
      overflow: 'hidden',
    },
    projectCardBanner: {
      maxHeight: '517px',
      margin: '-1.5rem',
      position: 'relative',
      '&:after': {
        position: 'absolute',
        content: '" "',
        top: '50%',
        left: 0,
        right: 0,
        bottom: 0,
        background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%)',
      },
    },
    projectName: {
      fontSize: '20px',
      lineHeight: '30px',
      fontFeatureSettings: "'tnum' on, 'lnum' on",
    },
    tokenTag: {
      fontSize: '16px',
      lineHeight: '24px',
      fontFeatureSettings: "'tnum' on, 'lnum' on",
      color: '#a9a9b0',
      '&:not(:first-child)::before': {
        content: '" / "',
        display: 'inline-block',
        margin: '0 .5rem',
      },
    },
    projectCardDescription: {
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '24px',
      fontFeatureSettings: "'tnum' on, 'lnum' on",
      color: '#87878D',
    },
    projectRaise: {
      fontWeight: 700,
      fontSize: '24px',
      lineHeight: '34px',
      textAlign: 'right',
      fontFeatureSettings: "'tnum' on, 'lnum' on",
      color: '#EECE7C',
    },
    saleStatus: {
      position: 'absolute',
      zIndex: 1,
      top: '16px',
      right: 0,
      fontSeight: 700,
      fontSize: '14px',
      lineHeight: '24px',

      height: '32px',
      background: '#00B074',
      borderRadius: '40px',
      padding: '4px 12px',
    },
  },
  {
    name: 'ProjectCard',
  },
);
export const ProjectCard = trackWindowScroll(({ className, project }) => {
  const classes = useStyles();
  return (
    <div
      href={project.url}
      className={clsx('project-card bg-gray-900 rounded-lg p-6', classes.projectCard, className, !!project.url)}
    >
      <div className='relative'>
        <div className={classes.projectCardBanner} style={{ maxHeight: '517px' }}>
          <LazyLoadImage src={project.banner} alt={project.name} className='w-full object-cover' width={219} />
        </div>
      </div>

      <div className='mt-10'>
        <div className='h-[60px] flex'>
          <h5 className={clsx('text-white font-bold text-ellipsis--2', classes.projectName, )}>{project.name}</h5>
        </div>

        <div className='mb-4 flex justify-between items-center mt-2'>
          <div className='text-[#87878D] font-semibold semibold'>Reward</div>
          <div className=''>
            <span className='font-bold semibold'>{project.reward} </span>
          </div>
        </div>
        <div className='mb-4 flex justify-between items-center'>
          <div className='text-[#87878D] font-semibold semibold'>Start</div>
          <div className=''>
            <span className='font-bold semibold'>{project.starts}</span>
          </div>
        </div>
        <div className='mb-4 flex justify-between items-center'>
          <div className='text-[#87878D] font-semibold semibold'>End</div>
          <div className=''>
            <span className='font-bold semibold'>{project.end} </span>
          </div>
        </div>
        {project.status !== projectStatuses.upcoming && (
          <Button
            className='w-full h-[36px]'
            disabled={project.status !== projectStatuses.active}
            href={project.url}
            target='_blank'
          >
            Join Now
          </Button>
        )}
      </div>
    </div>
  );
});
