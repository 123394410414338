import { renderProjectRoutes } from './helpers';

import { routesConfig as boomiRoutesConfig } from 'views/Boomi';
import { routesConfig as mcGeeRoutesConfig } from 'views/McGee';
import { routesConfig as animaliaRoutesConfig } from 'views/Animalia';
import { routesConfig as dinoXRoutesConfig } from 'views/DinoX';
import { routesConfig as bitHotelRoutesConfig } from 'views/BitHotel';
import { routesConfig as ritestreamRoutesConfig } from 'views/Ritestream';
import { routesConfig as wizardiaRoutesConfig } from 'views/Wizardia';
import { routesConfig as supernovaRoutesConfig } from 'views/Supernova';
import { routesConfig as animalConcertsRoutesConfig } from 'views/AnimalConcerts';
import { routesConfig as animalConcertsFsRoutesConfig } from 'views/AnimalConcertsFS';
import { routesConfig as karmaverseRoutesConfig } from 'views/Karmaverse';
import { routesConfig as ookeengaRoutesConfig } from 'views/Ookeenga';
import { routesConfig as kaizenRoutesConfig } from 'views/Kaizen';
import { routesConfig as roeRoutesConfig } from 'views/Roe';
import { routesConfig as metaSpetsRoutesConfig } from 'views/MetaSpets';
import { routesConfig as getKicksRoutesConfig } from 'views/GetKicks';
import { routesConfig as blocksRoutesConfig } from 'views/Blocks';
import { routesConfig as spumeRoutesConfig } from 'views/Spume';
import { routesConfig as ookeengaIDORoutesConfig } from 'views/OokeengaIDO';
import { routesConfig as galaxyArenaRoutesConfig } from 'views/GalaxyArena';

export const renderOtherProjectsRoutes = () => {
  return (
    <>
      {renderProjectRoutes(boomiRoutesConfig)}
      {renderProjectRoutes(mcGeeRoutesConfig)}
      {renderProjectRoutes(animaliaRoutesConfig)}
      {renderProjectRoutes(dinoXRoutesConfig)}
      {renderProjectRoutes(bitHotelRoutesConfig)}
      {renderProjectRoutes(ritestreamRoutesConfig)}
      {renderProjectRoutes(wizardiaRoutesConfig)}
      {renderProjectRoutes(supernovaRoutesConfig)}
      {renderProjectRoutes(animalConcertsRoutesConfig)}
      {renderProjectRoutes(animalConcertsFsRoutesConfig)}
      {renderProjectRoutes(karmaverseRoutesConfig)}
      {renderProjectRoutes(ookeengaRoutesConfig)}
      {renderProjectRoutes(kaizenRoutesConfig)}
      {renderProjectRoutes(roeRoutesConfig)}
      {renderProjectRoutes(metaSpetsRoutesConfig)}
      {renderProjectRoutes(getKicksRoutesConfig)}
      {renderProjectRoutes(blocksRoutesConfig)}
      {renderProjectRoutes(spumeRoutesConfig)}
      {renderProjectRoutes(ookeengaIDORoutesConfig)}
      {/* {renderProjectRoutes(swordOfBloodRoutesConfig)} */}
      {/* {renderProjectRoutes(moonMetaverseRoutesConfig)} */}
      {renderProjectRoutes(galaxyArenaRoutesConfig)}
    </>
  );
};