import React, { memo } from 'react';
import { Button, Grid } from '@mui/material';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  bannerItem: {
    position: 'relative',
    display: 'flex',
    height: 800,
    background: `url(${'/assets/imgs/airdrops/banner.png'})`,
    backgroundPosition: 'top center',
    objectFit: 'cover',
    backgroundSize: 'cover',
    flexDirection: 'column',
  },
});

export const Banner = memo(() => {
  const classes = useStyles();
  return (
    <div>
      <div
        className={[
          'relative flex justify-center items-center pb-10 px-6 w-full object-cover',
          classes.bannerItem,
        ].join(' ')}
      >
        <Grid
          container
          className='flex'
          style={{
            maxWidth: '1200px',
          }}
        >
          <Grid item xs={10} md={8} lg={6} xl={6} className='flex flex-col'>
            <div className='flex flex-col gap-[40px]'>
              <div className='text-[60px] font-bold'>SPORES EXCLUSIVE EVENTS</div>
              <div className='max-w-[455px] text-[#B9B9C3] text-lg'>
                Explore and win from Spores EXCLUSIVE events, all in one place! Complete simple tasks for a chance to
                be the winner of our amazing prize pools.
              </div>
              <Button
                className='w-[100px] h-[36px] rounded-[100px] font-bold text-[#403821]'
                href='https://t.me/sporeseng'
                target='_blank'
              >
                Join Now
              </Button>
            </div>
          </Grid>
          <Grid item xs={2} md={4} lg={6} xl={6} className='flex flex-col items-center justify-center'>
          <img className='max-h-[400px] max-w-[650px] hidden lg:flex' src={'/assets/imgs/airdrops/logo.png'} />
          </Grid>
        </Grid>
      </div>
    </div>
  );
});
