import { projectStatuses } from 'data/project-statuses';

export const SporexSpumeGiveaway = {
  name: '💎 $1000 GIVEAWAY - Spores x Spume 💎',
  banner: process.env.PUBLIC_URL + `/assets/imgs/airdrops/SporexSpumeGiveaway.jpg`,
  reward: '$1000 in SPUME token',
  url: `https://t.me/sporesofficial/1344`,
  starts: 'August 22',
  end: 'August 27',
  status: projectStatuses.completed,
};

export const okgSpecialGiveaway = {
  name: '100,000 $OKG OOKEENGA SPECIAL GIVEAWAY',
  banner: process.env.PUBLIC_URL + `/assets/imgs/airdrops/okgSpecialGiveaway.jpg`,
  reward: '100,000 OKG token',
  url: `https://t.me/ookeenga_official/616`,
  starts: 'August 10',
  end: 'August 29',
  status: projectStatuses.completed,
};

export const tournamentBeta = {
  name: 'TOURNAMENT BETA VERSION',
  banner: process.env.PUBLIC_URL + `/assets/imgs/airdrops/tournamentBeta.jpg`,
  reward: '$5000 in OKG token',
  url: `https://t.me/ookeenga_official/561`,
  starts: 'August 1',
  end: 'August 29',
  status: projectStatuses.completed,
};

export const SporesxOokeengaHotDeals = {
  name: 'BUY 5 GET 1 FREE COCOON + $SPO CASH BACK',
  banner: process.env.PUBLIC_URL + `/assets/imgs/airdrops/SporesxOokeengaHotDeals.jpg`,
  reward: 'Free cocoons, SPO cash back',
  url: `https://t.me/sporesofficial/1326`,
  starts: 'August 11',
  end: 'August 18',
  status: projectStatuses.completed,
};

export const SporesxOokeengaEpicNftGiveaway = {
  name: 'OOKEENGA EPIC NFT GIVEAWAY',
  banner: process.env.PUBLIC_URL + `/assets/imgs/airdrops/SporesxOokeengaEpicNftGiveaway.jpg`,
  reward: '20 Ookeenga’s Genesis Cocoons',
  url: `https://t.me/sporesofficial/1320`,
  starts: 'August 10',
  end: 'August 18',
  status: projectStatuses.completed,
};

export const BetaTestExclusiveEvent = {
  name: 'BATTLE OF CHIEFTAINS',
  banner: process.env.PUBLIC_URL + `/assets/imgs/airdrops/BetaTestExclusiveEvent.jpg`,
  reward: '6 Ookeenga’s Genesis Cocoons',
  url: `https://t.me/ookeenga_official/618`,
  starts: 'August 10',
  end: 'August 24',
  status: projectStatuses.completed,
};
