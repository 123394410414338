import React, { useMemo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { keys, toLower } from 'ramda';

import { projectAlias } from 'data';
import { AppFooter, AppHeader, AppPreHeader } from 'containers';

import styles from './PrivateLayout.module.scss';

export const PrivateLayout = () => {
  const location = useLocation();

  const isDisplayedPreHeader = useMemo(() => {
    const currentAlias = toLower(location.pathname.replace('/', ''));
    return keys(projectAlias).some((alias) => currentAlias.startsWith(toLower(alias)));
  }, [location.pathname]);

  return (
    <main className='min-h-screen flex flex-col'>
      {isDisplayedPreHeader && <AppPreHeader />}
      <AppHeader />
      <div className={styles.contentContainer}>
        <Outlet />
      </div>
      <AppFooter />
    </main>
  );
};
