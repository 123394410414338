import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
import { PrivateLayout, ProjectLayout } from 'layouts';
import { AppController, AppTheme } from 'containers';
import { queryClient } from 'services/client';
import { ServicesContextProvider } from 'services/ServicesContext';

import { Home } from 'views/Home';
import { Airdrop } from 'views/Airdrop';
import { Loading } from 'components';
import {
  renderINOProjectsRoutes,
  renderOtherProjectsRoutes, 
} from 'routes';

import './App.scss';

export const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <AppTheme>
        <Router>
          <ServicesContextProvider>
            <AppController>
              <Suspense fallback={<Loading text='Loading, please wait...' />}>
                <Routes>
                  <Route path='/' element={<PrivateLayout />}>
                    <Route index element={<Home />} />
                    <Route path='/airdrop' element={<Airdrop />} />
                    <Route path='/ino' element={<ProjectLayout />}>
                      {renderINOProjectsRoutes()}
                    </Route>
                    <Route path='/:type' element={<ProjectLayout />}>
                      {renderOtherProjectsRoutes()}
                    </Route>
                  </Route>
                </Routes>
              </Suspense>
            </AppController>
          </ServicesContextProvider>
        </Router>
      </AppTheme>
    </QueryClientProvider>
  );
};
