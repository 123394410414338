import {
  equals,
  find,
  toLower,
  propOr,
  compose,
  // tap,
} from 'ramda';
import { projectAlias } from './project-alias';
import { API_URL } from 'env';
import { projectData as supernovaData } from '../views/Supernova/Data';
import { projectData as animalconcertsData } from '../views/AnimalConcerts/Data';
import { projectData as animalconcertsFSData } from '../views/AnimalConcertsFS/Data';
import { projectData as karmaverseData } from '../views/Karmaverse/Data';
import { projectData as ritestreamData } from '../views/Ritestream/Data';
import { projectData as ookeengaData } from '../views/Ookeenga/Data';
import { projectData as ookeengaINOData } from '../views/OokeengaINO/Data';
import { projectData as roeData } from '../views/Roe/Data';
import { projectData as kaizenData } from '../views/Kaizen/Data';
import { projectData as metaSpetsData } from '../views/MetaSpets/Data';
import { projectData as getKicksData } from '../views/GetKicks/Data';
import { projectData as blocksData } from '../views/Blocks/Data';
import { projectData as ookeengaINOPublicPoolData } from '../views/OokeengaINOPublicPool/Data';
import { projectData as spumeData } from '../views/Spume/Data';
import { projectData as ookeengaIDOData } from '../views/OokeengaIDO/Data';
import { projectData as swordOfBloodData } from '../views/SwordOfBlood/Data';
import { projectData as moonMetaverseData } from '../views/MoonMetaverse/Data';
import { projectData as ookeengaINO2Data } from '../views/OokeengaINO2/Data';
import { projectData as galaxyArenaData } from '../views/GalaxyArena/Data';

export const projectConfigs = [
  {
    alias: projectAlias.legacyProjects,
    apiBaseUrl: API_URL,
  },
  supernovaData,
  animalconcertsData,
  karmaverseData,
  ritestreamData,
  animalconcertsFSData,
  ookeengaData,
  ookeengaINOData,
  roeData,
  kaizenData,
  metaSpetsData,
  getKicksData,
  blocksData,
  ookeengaINOPublicPoolData,
  spumeData,
  ookeengaIDOData,
  swordOfBloodData,
  moonMetaverseData,
  ookeengaINO2Data,
  galaxyArenaData,
];

export const getProjectConfigByAlias = (alias) => find(
  compose(
    equals(toLower(alias)),
    toLower,
    propOr('', 'alias'),
  ),
  projectConfigs
);

export const getLegacyProjectConfig = () => getProjectConfigByAlias(projectAlias.legacyProjects);
