import {
  SporexSpumeGiveaway,
  okgSpecialGiveaway,
  tournamentBeta,
  SporesxOokeengaHotDeals,
  SporesxOokeengaEpicNftGiveaway,
  BetaTestExclusiveEvent,
} from './airdrop-data';

export const projectsData = {
  activeProjectsData: [],
  upcomingProjectsData: [],
  completedSalesData: [
    SporexSpumeGiveaway,
    okgSpecialGiveaway,
    tournamentBeta,
    SporesxOokeengaHotDeals,
    SporesxOokeengaEpicNftGiveaway,
    BetaTestExclusiveEvent,
  ],
};
