import { renderProjectRoutes } from './helpers';

import { routesConfig as ookeengaINORoutesConfig } from 'views/OokeengaINO';
import { routesConfig as ookeengaINOPublicPoolRoutesConfig } from 'views/OokeengaINOPublicPool';
import { routesConfig as ookeengaINO2RoutesConfig } from 'views/OokeengaINO2';

export const renderINOProjectsRoutes = () => {
  return (
    <>
      {renderProjectRoutes(ookeengaINORoutesConfig)}
      {renderProjectRoutes(ookeengaINOPublicPoolRoutesConfig)}
      {renderProjectRoutes(ookeengaINO2RoutesConfig)}
    </>
  );
};
