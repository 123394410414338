import { projectData } from './Data';

export const routesConfig = {
  root: projectData.alias,
  routes: [
    {
      path: '',
      loadComponent: () => import('./Landing'),
    },
    {
      path: 'order-history',
      loadComponent: () => import('./Order'),
    },
  ],
};
