import { projectAlias } from 'data';
import { projectStatuses, saleStatuses } from 'data/project-statuses';
import { BASE_API_URL } from 'env';

export const isTest = false;

export const hardCodeTime = isTest
  ? {
      open_time: '2022-03-23T13:00:00Z',
      double_limit_round_time: '2022-03-24T01:00:00Z',
      fcfs_round_time: '2022-03-24T02:00:00Z',
      close_time: '2022-03-24T14:00:00Z',
    }
  : null;


export const projectData = {
  name: 'Supernova',
  alias: projectAlias.supernova,
  whiteListUrl: 'https://docs.google.com/forms/d/e/1FAIpQLSexOK7VhfMriJFDWu-jo8DitLl63-o-6gHGHpVrR7t2nCOgxA/viewform',
  apiBaseUrl: `${BASE_API_URL}/${projectAlias.supernova}`,
  addressReceiver: '',

  banner: `${process.env.PUBLIC_URL}/assets/imgs/projects/${projectAlias.supernova}/banner.png`,
  bigBanner: `${process.env.PUBLIC_URL}/assets/imgs/projects/${projectAlias.supernova}/big-banner.png`,
  logo: `${process.env.PUBLIC_URL}/assets/imgs/projects/${projectAlias.supernova}/logo.png`,
  textLogo: `${process.env.PUBLIC_URL}/assets/imgs/projects/${projectAlias.supernova}/text-logo.png`,
  url: `/ido/${projectAlias.supernova}`,
  saleType: 'IDO',
  acceptedTokens: ['BUSD', 'USDT'],
  totalRaise: 30000,
  meta: [
    {
      label: 'Price',
      value: 0.3,
      prefix: '$',
    },
  ],
  starts: 'March 23, 13:00 UTC',
  enable: true,
  status: projectStatuses.completed,
  saleStatus: saleStatuses.soldOut,
};
