import React from 'react';
import { Route } from 'react-router-dom';
import { lazyLoadThenCreateComponent } from 'utils/common';

export const renderProjectRoutes = (routesConfig) => {
  return (
    <Route path={routesConfig.root}>
      {routesConfig.routes.map((routeConfig, index) => (
        <Route key={index} path={routeConfig.path} element={lazyLoadThenCreateComponent(routeConfig.loadComponent)} />
      ))}
    </Route>
  );
};
