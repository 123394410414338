import { getProjectConfigByAlias, projectAlias } from 'data';
import { compose } from 'ramda';
import { getClient } from './axios';
import { isINO } from './helpers';

const marketServices = {};

export const createMarketService = (projectConfig) => {
  const { client } = getClient(projectConfig);
  const prefix = isINO() ? '/ino' : '';
  const getQuota = () => client.get(`${prefix}/userlevel`);
  const fetchProducts = (params) => client.get(`${prefix}/products`, { params });
  const fetchOrders = (params) => client.get(`${prefix}/order/history`, { params });
  const fetchCampaignsTime = () => client.get(`${prefix}/campaigntime`);
  const checkout = (body) => client.post(`${prefix}/order`, body);
  const submitOrder = ({ order_id, ...body }) => client.post(`${prefix}/order/${order_id}/submit`, body);
  const cancelOrder = ({ order_id }) => client.delete(`${prefix}/order/${order_id}`);
  const getMaxAmount = () => client.get(`${prefix}/max-amount`);

  marketServices[projectConfig.alias] = {
    getQuota,
    fetchProducts,
    fetchOrders,
    fetchCampaignsTime,
    checkout,
    submitOrder,
    cancelOrder,
    getMaxAmount,
  };

  return marketServices[projectConfig.alias];
};

export const getMarketService = (projectConfig) => {
  const { alias } = projectConfig;
  return marketServices[alias] || createMarketService(projectConfig);
};

export const marketService = compose(
  getMarketService,
  getProjectConfigByAlias
)(projectAlias.legacyProjects)
