import { projectAlias } from 'data';
import { projectStatuses, saleStatuses } from 'data/project-statuses';
import { BASE_API_URL } from 'env';

export const isTest = false;

export const hardCodeTime = isTest
  ? {
      open_time: '2022-04-08T13:00:00Z',
      double_limit_round_time: '2022-04-09T13:00:00Z',
      fcfs_round_time: '2022-04-09T14:00:00Z',
      close_time: '2022-04-10T14:00:00Z',
    }
  : null;

export const projectData = {
  name: 'Ookeenga',
  alias: projectAlias.ookeenga,
  whiteListUrl: 'https://docs.google.com/forms/d/e/1FAIpQLSdpmysVy4q68BKpvomUhg_7Mlc7D8cerIfN-JYJsnHSgAYtew/viewform',
  apiBaseUrl: `${BASE_API_URL}/${projectAlias.ookeenga}`,
  addressReceiver: process.env.REACT_APP_ADDRESS_RECEIVER,

  banner: `${process.env.PUBLIC_URL}/assets/imgs/projects/${projectAlias.ookeenga}/banner.png`,
  bigBanner: `${process.env.PUBLIC_URL}/assets/imgs/projects/${projectAlias.ookeenga}/big-banner.png`,
  inventory: `${process.env.PUBLIC_URL}/assets/imgs/projects/${projectAlias.ookeenga}/inventory.png`,
  logo: `${process.env.PUBLIC_URL}/assets/imgs/projects/${projectAlias.ookeenga}/logo.png`,
  textLogo: `${process.env.PUBLIC_URL}/assets/imgs/projects/${projectAlias.ookeenga}/text-logo.png`,
  url: `/ido/${projectAlias.ookeenga}`,
  saleType: 'Private Sale',
  acceptedTokens: ['BUSD', 'USDT'],
  totalRaise: 50000,
  meta: [
    {
      label: 'Price',
      value: 0.04,
      prefix: '$',
    },
  ],
  starts: 'April 08, 13:00 UTC',
  enable: true,
  status: projectStatuses.completed,
  tokenName: 'Ookeenga',
  tokenSymbol: 'OKG',
  totalSupply: 500000000,
  initialSupply: 7200000,
  initialMarketCap: 360000,
  tokenListing: 'TBA',
  registration_open_time: '2022-03-23T13:00:00Z',
  registration_close_time: '2022-04-07T13:00:00Z',
  allocation_round_time: '2022-03-26T13:00:00Z',
  saleStatus: saleStatuses.soldOut,
};
