import { useMemo, useCallback } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  NetworkBar,
  // SubscribeEmail,
} from 'components';
import { usePopover } from 'hooks';
import { AppBar, Button, Link as MuiLink, ListItemButton, Popover, Toolbar } from '@mui/material';
import { connectWallet } from 'reducers/profileAction';
import { signOut } from 'reducers/profileSlice';
import { shorten } from 'utils/common';
import { useServicesContext } from 'services/ServicesContext';
import { projectAlias } from 'data';

let activeStyle = {
  color: '#EECE7C',
};

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { isLoggedIn, address } = useSelector(({ profile }) => profile);

  const servicesContext = useServicesContext();

  const [isOpen, anchor, onClick, onClose] = usePopover();
  const isNetwork = useMemo(
    () =>
      location.pathname.startsWith(projectAlias.wizardia) ||
      servicesContext.projectConfig.alias === projectAlias.supernova ||
      servicesContext.projectConfig.alias === projectAlias.animalconcerts ||
      servicesContext.projectConfig.alias === projectAlias.animalconcertsFS ||
      servicesContext.projectConfig.alias === projectAlias.karmaverse ||
      servicesContext.projectConfig.alias === projectAlias.ritestream ||
      servicesContext.projectConfig.alias === projectAlias.ookeenga ||
      // servicesContext.projectConfig.alias === projectAlias.roe ||
      servicesContext.projectConfig.alias === projectAlias.kaizen ||
      servicesContext.projectConfig.alias === projectAlias.ookeengaINO ||
      servicesContext.projectConfig.alias === projectAlias.metaSpets ||
      servicesContext.projectConfig.alias === projectAlias.getKicks ||
      servicesContext.projectConfig.alias === projectAlias.blocks ||
      servicesContext.projectConfig.alias === projectAlias.spume ||
      servicesContext.projectConfig.alias === projectAlias.ookeengaIDO ||
      servicesContext.projectConfig.alias === projectAlias.swordOfBlood ||
      servicesContext.projectConfig.alias === projectAlias.moonMetaverse ||
      servicesContext.projectConfig.alias === projectAlias.ookeengaINO2 ||
      servicesContext.projectConfig.alias === projectAlias.galaxyArena ||
      servicesContext.projectConfig.alias === projectAlias.ookeengaINOPublicPool,
    [location, servicesContext.projectConfig],
  );
  const onConnectWallet = useCallback(() => connectWallet(servicesContext), [servicesContext]);

  const onOrderHistoryClicked = () => {
    if (servicesContext.projectConfig.saleType === 'INO') {
      navigate(`/ino/${servicesContext.projectConfig.alias}/order-history`);
    } else {
      navigate(`/ido/${servicesContext.projectConfig.alias}/order-history`);
    }
  };

  return (
    <div className='relative'>
      <AppBar position='sticky' elevation={0} style={{ backgroundColor: 'rgba(29, 26, 33, 0.8)' }}>
        <Toolbar>
          <a href='/' target={'_blank'}>
            <img src={require('assets/icons/logo-spores-invert.svg').default} className='h-8 sm:h-10' />
          </a>
          <div className='mx-6'>{isNetwork && <NetworkBar visible />}</div>

          <div className='flex-1 flex items-center justify-end'>
            <MuiLink className='sm:inline hidden text-gray-200 hover:text-white mr-6'>
              <a href='/' target={'_blank'}>
                Launchpad
              </a>
            </MuiLink>
            <MuiLink href='/airdrop' className='sm:inline hidden text-gray-200 hover:text-white mr-6'>
              <NavLink to='/airdrop' style={({ isActive }) => (isActive ? activeStyle : undefined)}>
                Airdrop
              </NavLink>
            </MuiLink>
            <MuiLink
              href='https://marketplace.spores.app'
              className='sm:inline hidden text-gray-200 hover:text-white mr-6'
            >
              Marketplace
            </MuiLink>
            <MuiLink
              href='https://republique.spores.app/'
              className='sm:inline hidden text-gray-200 hover:text-white mr-6'
            >
              République
            </MuiLink>
            <MuiLink href='https://games.spores.app' className='sm:inline hidden text-gray-200 hover:text-white mr-6'>
              Gamestore
            </MuiLink>
            {isNetwork && (
              <div className='flex items-center'>
                {!isLoggedIn ? (
                  <Button onClick={onConnectWallet}>Connect wallet</Button>
                ) : (
                  <div>
                    <Button
                      size='small'
                      variant='text'
                      className='text-gray-300 text-sm px-4'
                      style={{ backgroundColor: '#3C3C3E', border: '1px solid #767679' }}
                      onClick={onClick}
                    >
                      {shorten(address)}
                    </Button>
                    <Popover open={isOpen} anchorEl={anchor} onClose={onClose}>
                      <ListItemButton
                        className='text-sm'
                        onClick={onOrderHistoryClicked}
                        style={{ backgroundColor: '#3C3C3E' }}
                      >
                        Order history
                      </ListItemButton>
                      <ListItemButton
                        style={{ backgroundColor: '#3C3C3E' }}
                        className='text-sm'
                        onClick={() => {
                          onClose();
                          navigator.clipboard.writeText(address);
                        }}
                      >
                        Copy Address
                      </ListItemButton>
                      <ListItemButton
                        style={{ backgroundColor: '#3C3C3E' }}
                        className='text-sm'
                        onClick={() => {
                          onClose();
                          dispatch(signOut());
                        }}
                      >
                        Disconnect
                      </ListItemButton>
                    </Popover>
                  </div>
                )}
              </div>
            )}
          </div>
        </Toolbar>
      </AppBar>
      {/* <SubscribeEmail /> */}
    </div>
  );
};

export default Header;
