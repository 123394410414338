export const projectAlias = {
  legacyProjects: 'legacyProjects',
  animalia: 'animalia',
  bithotel: 'bithotel',
  superBOOMi:'Super-BOOMi',
  dinoX: 'dinoX',
  americanMcGee: 'americanmcgee',
  wizardia: 'wizardia',
  ritestream: 'ritestream',
  cryptoverse: 'cryptoverse',
  supernova: 'supernova',
  animalconcerts: 'animalconcerts',
  animalconcertsFS: 'animalconcerts-flashpool',
  karmaverse: 'karmaverse',
  ookeenga: 'ookeenga',
  ookeengaINO: 'ookeenga-INO',
  roe: 'realmsofeth',
  kaizen: 'kaizen',
  metaSpets: 'metaspets',
  getKicks: 'getkicks',
  blocks: 'blocks',
  ookeengaINOPublicPool: 'ookeenga-INO-public',
  spume: 'spume',
  ookeengaIDO: 'ookeenga-IDO',
  swordOfBlood: 'swords-of-blood-IDO',
  moonMetaverse: 'moon-metaverse-IDO',
  ookeengaINO2: 'ookeenga-INO-public-2',
  galaxyArena: 'galaxy-arena-IDO',
};
