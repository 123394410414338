import { projectData as supernovaProjectData } from 'views/Supernova/Data';
import { projectData as animalConcertProjectData } from 'views/AnimalConcerts/Data';
import { projectData as animalConcertFSProjectData } from 'views/AnimalConcertsFS/Data';
import { projectData as karmaverseProjectData } from 'views/Karmaverse/Data';
import { projectData as ritestreamProjectData } from 'views/Ritestream/Data';
import { projectData as ookeengaProjectData } from 'views/Ookeenga/Data';
import { projectData as roeProjectData } from 'views/Roe/Data';
import { projectData as kaizenProjectData } from 'views/Kaizen/Data';
import { projectData as ookeengaINOProjectData } from 'views/OokeengaINO/Data';
import { projectData as metaSpetsProjectData } from 'views/MetaSpets/Data';
import { projectData as getkicksProjectData } from 'views/GetKicks/Data';
import { projectData as blocksProjectData } from 'views/Blocks/Data';
import { projectData as ookeengaINOPublicPoolProjectData } from 'views/OokeengaINOPublicPool/Data';
import { projectData as spumeProjectData } from 'views/Spume/Data';
import { projectData as OokeengaIDOData } from 'views/OokeengaIDO/Data';
import { projectData as SwordOfBloodData } from 'views/SwordOfBlood/Data';
import { projectData as MoonMetaverseData } from 'views/MoonMetaverse/Data';
import { projectData as ookeengaINO2ProjectData } from 'views/OokeengaINO2/Data';
import { projectData as galaxyArenaProjectData } from 'views/GalaxyArena/Data';


import {
  wizardiaProjectData,
  dinoXLandProjectData,
  bitHotelProjectData,
  animaliaProjectData,
  plushieDreadfulsProjectData,
  superBoomiProjectData,
  polkaFantasyLandProjectData,
  netVRkTransportProjectData,
  netVRkLandProjectData,
} from './legacy-projects';

export const projectsData = [
  // active

  // upcoming
  galaxyArenaProjectData,
  ookeengaINO2ProjectData,
  SwordOfBloodData,
  MoonMetaverseData,

  // SwordOfBloodData,

  // completed
  OokeengaIDOData,
  spumeProjectData,
  ookeengaINOPublicPoolProjectData,
  blocksProjectData,
  getkicksProjectData,
  metaSpetsProjectData,
  ookeengaINOProjectData,
  kaizenProjectData,
  roeProjectData,
  ookeengaProjectData,
  animalConcertFSProjectData,
  karmaverseProjectData,
  animalConcertProjectData,
  supernovaProjectData,
  wizardiaProjectData,
  ritestreamProjectData,
  dinoXLandProjectData,
  bitHotelProjectData,
  animaliaProjectData,
  plushieDreadfulsProjectData,
  superBoomiProjectData,
  polkaFantasyLandProjectData,
  netVRkTransportProjectData,
  netVRkLandProjectData,
];
