import styles from './Steps.module.scss';

const Step = ({ displayType, no, imageUrl, title, url, className }) => {
  return (
    <a
      href={url}
      target='_blank'
      className={[
        styles.stepContainer,
        'flex flex-row w-full justify-center mt-6 md:flex-col md:w-[unset]md:mt-0 z-10',
        className,
      ].join(' ')}
    >
      {displayType !== 2 && <span className={[styles.stepNo, 'w-1/3 md:w-full'].join(' ')}>{no}</span>}
      <div className={[styles.step, 'flex justify-center items-center mt-0 md:mt-4'].join(' ')}>
        <img className={styles.stepIcon} src={imageUrl} alt='icon 1' width={38} height={38} />
      </div>
      <span className={[styles.stepTitle, 'w-1/3 ml-6 text-left md:w-full md:ml-0 md:mt-6 md:text-center'].join(' ')}>
        {title}
      </span>
    </a>
  );
};

export const Steps = ({ displayType, className }) => {
  return (
    <div
      className={['flex-col md:flex-row justify-between items-center', styles.steps, className].join(' ')}
      display-type={displayType}
    >
      <Step
        displayType={displayType}
        no='1'
        imageUrl={require('./images/icon-01.svg').default}
        title='STAKE'
        url={'https://staking.spores.app/'}
        className='items-center'
      />
      <Step
        displayType={displayType}
        no='2'
        imageUrl={require('./images/icon-02.svg').default}
        title='KYC'
        url={'https://in.sumsub.com/idensic/l/#/uni_dgsdMlCWmLETygri'}
        className='items-center'
      />
      <Step
        displayType={displayType}
        no='3'
        imageUrl={require('./images/icon-03.svg').default}
        title='REGISTER'
        className='items-center'
      />
      <Step
        displayType={displayType}
        no='4'
        imageUrl={require('./images/icon-04.svg').default}
        title='BUY'
        className='items-center'
      />
      <Step
        displayType={displayType}
        no='5'
        imageUrl={require('./images/icon-05.svg').default}
        title='CLAIM'
        className='items-center'
      />
    </div>
  );
};
