import { projectAlias } from 'data';
import { projectStatuses, saleStatuses } from 'data/project-statuses';
import { BASE_API_URL } from 'env';

export const isTest = false;

export const hardCodeTime = false
  ? {
    registration_time: '2022-08-10T10:00:00Z',
      open_time: '2022-08-11T10:00:00Z',
      fcfs_round_time: '2022-08-11T10:00:00Z',
      close_time: '2022-08-18T00:10:00Z',
    }
  : null;

export const projectData = {
  name: 'Ookeenga INO Public Pool',
  alias: projectAlias.ookeengaINOPublicPool,
  whiteListUrl: 'https://forms.gle/jWpTaFsXmGUrh5Vz6',
  apiBaseUrl: `${BASE_API_URL}/${projectAlias.ookeengaINOPublicPool}`,
  addressReceiver: process.env.REACT_APP_ADDRESS_RECEIVER,

  banner: `${process.env.PUBLIC_URL}/ino/static/imgs/projects/ookeenga-INO-public/banner.png`,
  bigBanner: `${process.env.PUBLIC_URL}/ino/static/imgs/projects/ookeenga-INO-public/big-banner1.png`,
  inventory: `${process.env.PUBLIC_URL}/ino/static/imgs/projects/ookeenga-INO-public/inventory.png`,
  logo: `${process.env.PUBLIC_URL}/ino/static/imgs/projects/ookeenga-INO-public/logo.png`,
  textLogo: `${process.env.PUBLIC_URL}/ino/static/imgs/projects/ookeenga-INO-public/text-logo.png`,
  url: `/ino/${projectAlias.ookeengaINOPublicPool}`,
  saleType: 'INO',
  acceptedTokens: ['BUSD', 'USDT'],
  totalRaise: 50000,
  meta: [
    {
      label: 'Quantity',
      value: 500,
      prefix: '',
      price: 100
    },
  ],
  starts: 'August 11, 10:00',
  apiDown: false,
  enable: true,
  status: projectStatuses.completed,
  saleStatus: saleStatuses.soldOut,
  tokenName: '$Ookeenga',
  tokenSymbol: 'OKG',
  totalSupply: '1600000000',
  initialSupply: '13333333',
  initialMarketCap: '800000',
  tokenListing: 'TBA',
  registration_open_time: 'TBA',
  registration_close_time: '2022-07-05T09:00:00Z',
  allocation_round_time: '2022-07-05T13:00:00Z',
};
