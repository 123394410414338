import { memo } from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Link as MuiLink, Toolbar, Typography } from '@mui/material';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(
  {
    footer: {
    },
    '@media (max-width: 767px)': {
      footer: {
        flexDirection: 'column',
      },
    },
  },
  {
    name: 'LayoutFooter',
  },
);

const Footer = memo(() => {
  const classes = useStyles();

  return (
    <AppBar position='sticky' elevation={0} style={{ backgroundColor: '#232323' }}>
      <Toolbar className={classes.footer}>
        <Link to="/" className="my-6">
          <img src={require('assets/icons/logo-spores-invert.svg').default} className='h-8 sm:h-10' />
        </Link>

        <div className='md:flex flex-1 justify-end text-gray-200 grid md:grid-none grid-cols-2 md:grid-cols-none'>
          <MuiLink href='https://launchpad.spores.app' className='text-gray-200 hover:text-white mr-6'>
            Launchpad
          </MuiLink>
          <MuiLink href='https://marketplace.spores.app' className='text-gray-200 hover:text-white mr-6'>
            Marketplace
          </MuiLink>
          <MuiLink href='https://republique.spores.app/' className='text-gray-200 hover:text-white mr-6'>
            République
          </MuiLink>
          <MuiLink href='https://games.spores.app' className='text-gray-200 hover:text-white mr-6'>
            Gamestore
          </MuiLink>

          <Typography className='hidden md:inline'>
            Contact Us:{' '}
            <MuiLink href='mailto:customersupport@spores.app' className='text-gray-200 hover:text-white'>
              customersupport@spores.app
            </MuiLink>
          </Typography>
        </div>
      </Toolbar>
    </AppBar>
  );
});

export default Footer;
