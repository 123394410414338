export const formatNumber = (number, prefix, fractionDigits = -1) => {
  const beforeDecimalPoint = number.toString().split('.')[0];
  let afterDecimalPoint = number.toString().split('.')[1];
  if (fractionDigits >= 0) {
    afterDecimalPoint = afterDecimalPoint.slice(0, fractionDigits);
  }
  const hasAfterDecimalPoint = !!afterDecimalPoint;
  const localeBeforeDecimalPoint = Number(beforeDecimalPoint).toLocaleString();
  return (
    (!prefix ? localeBeforeDecimalPoint : `${prefix}${localeBeforeDecimalPoint}`) +
    (hasAfterDecimalPoint ? `.${afterDecimalPoint}` : '')
  );
};

export const shorten = (address, head = 6, tail = 4) => {
  if (typeof address !== 'string') return address;
  return address.slice(0, head) + '...' + address.slice(address.length - tail);
};

export * from './formatTwoDigits';
export * from './isNumeric';
export * from './date';
export * from './lazyLoadThenCreateComponent';
