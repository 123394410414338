import { projectAlias } from 'data';
import { projectStatuses, saleStatuses } from 'data/project-statuses';
import { BASE_API_URL } from 'env';

export const isTest = false;

export const hardCodeTime = false
  ? {
    registration_time: '2022-07-05T09:00:00Z',
      open_time: '2022-07-05T13:00:00Z',
      fcfs_round_time: '2022-07-05T19:00:00Z',
      close_time: '2022-07-06T00:00:00Z',
    }
  : null;

export const projectData = {
  name: 'BLOCKS IDO',
  alias: projectAlias.blocks,
  whiteListUrl: 'https://docs.google.com/forms/d/e/1FAIpQLSearg3kZfAlUshyIkp2NNrXloxWzuQNyarQAEaVKG_bgVqpJA/viewform',
  apiBaseUrl: `${BASE_API_URL}/${projectAlias.blocks}`,
  addressReceiver: process.env.REACT_APP_ADDRESS_RECEIVER,

  banner: `${process.env.PUBLIC_URL}/assets/imgs/projects/blocks/banner.png`,
  bigBanner: `${process.env.PUBLIC_URL}/assets/imgs/projects/blocks/big-banner.png`,
  inventory: `${process.env.PUBLIC_URL}/assets/imgs/projects/blocks/inventory.png`,
  logo: `${process.env.PUBLIC_URL}/assets/imgs/projects/blocks/logo.png`,
  textLogo: `${process.env.PUBLIC_URL}/assets/imgs/projects/blocks/text-logo.png`,
  url: `/ido/${projectAlias.blocks}`,
  saleType: 'IDO',
  acceptedTokens: ['BUSD', 'USDT'],
  totalRaise: 50000,
  meta: [
    {
      label: 'Price',
      value: 0.06,
      prefix: '$',
    },
  ],
  starts: 'July 05, 13:00 UTC',
  apiDown: false,
  enable: true,
  status: projectStatuses.completed,
  saleStatus: saleStatuses.soldOut,
  tokenName: '$BLOCKS',
  tokenSymbol: '$BLOCKS',
  totalSupply: '1600000000',
  initialSupply: '13333333',
  initialMarketCap: '800000',
  tokenListing: 'TBA',
  registration_open_time: 'TBA',
  registration_close_time: '2022-07-05T09:00:00Z',
  allocation_round_time: '2022-07-05T13:00:00Z',
};
