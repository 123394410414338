import { compose } from 'ramda';
import { getProjectConfigByAlias, projectAlias } from 'data';
import { getClient } from './axios';
import { isINO } from './helpers';

const systemServices = {};

export const createSystemService = (projectConfig) => {
  const { client } = getClient(projectConfig);
  const prefix = isINO() ? '/ino' : '';
  const fetchCategories = () => client.get(`${prefix}/v1/nft/categories`);
  const fetchSubCategories = () => client.get(`${prefix}/v1/nft/sub-categories`);
  const fetchPaymentTokens = () => client.get(`${prefix}/v1/nft/payment-tokens`);
  const fetchSystemConfig = () => client.get(`${prefix}/v1/nft/systems`);

  systemServices[projectConfig.alias] = {
    fetchCategories,
    fetchSubCategories,
    fetchPaymentTokens,
    fetchSystemConfig,
  };

  return systemServices[projectConfig.alias];
};

export const getSystemService = (projectConfig) => {
  const { alias } = projectConfig;
  return systemServices[alias] || createSystemService(projectConfig);
};

export const systemService = compose(getSystemService, getProjectConfigByAlias)(projectAlias.legacyProjects);
